.skill-head {
    text-align: right;
}

/* .skill-bar {
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.12) !important;
} */

.skill-section {
    padding-bottom: 5%;
}

.skill-bar.MuiAppBar-colorPrimary {
    color: black !important;
    background-color: #eeeeee !important;
}

.MuiTab-wrapper {
    font-family: 'Do Hyeon', sans-serif !important;
    font-size: 1.1rem !important;
    font-weight: bold !important;
    letter-spacing: 2px !important;
}

@media screen and (max-width: 768px) {
    .MuiTab-wrapper {
        font-size: 0.8rem !important;
    }
}